<template>
    <div>
        <strong>Variant v{{ state.reference2ID }} Upload</strong>
        <br>
        <div class="form-group">
            <label>Item</label>
            <input type="text" disabled class="form-control" :value="nomenclature" />
        </div>
        <div class="form-group">
            <label>Type</label>
            <select class="form-control" v-model="state.fileTypeID">
                <option :value="$cdiVars.FILE_TYPE_THREED_DRWNG">
                    3d Drawing
                </option>
                <option :value="$cdiVars.FILE_TYPE_ENGINEERING_FL">
                    Engineering
                </option>
                <option :value="$cdiVars.FILE_TYPE_WELDING_FL">
                    Welding File
                </option>
                <option :value="$cdiVars.FILE_TYPE_KIT_CURB_BRAKE_FL">
                    Kit Curb Brake
                </option>
                <option :value="$cdiVars.FILE_TYPE_ASSEM_PARTLABEL_FL">
                    Assembly Part Label
                </option>
                <option :value="$cdiVars.FILE_TYPE_FIELD_ASSEM_DRWNG">
                    Field Assembly
                </option>
                <option :value="$cdiVars.FILE_TYPE_PRODUCTION_NOTES">
                    Production Notes
                </option>
                <option :value="$cdiVars.FILE_TYPE_E_DRAWING">
                    E-Drawing
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";

    export default {
        name: "CurbVariant",
        data() {
            return {
                state: fileUploadStore.state,
                nomenclature: this.$appStore.state.fileUploadContextData.data.nomenclature
            }
        },
        created() {
            fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_THREED_DRWNG);
        }
    }
</script>

<style scoped>

</style>